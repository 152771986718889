import { FETCH_REPORTS, ADD_USERCOMMENT, ADD_LIKE, REMOVE_LIKE, START_FETCING_REPORTS, NEW_REPORTS_FROM_LISTENER, START_ADDING_REPORT, REPORT_ADDED, CLEAR_REPORT_ADDING_STATUS, SET_MAP_SELECTED_MARKER, FILTER_REPORTS, FETCH_USER_REPORTS, REMOVE_REPORT, SET_REPORT_MARKER_ID } from './types';
import Feed from '../../interfaces/Feed';
import Comment from '../../interfaces/Comment';
import Report from '../../interfaces/Report';
import Like from '../../interfaces/Like';
import { getUserFeed, like, postReport, removeLike } from '../../utils/utils';
import MoanReport from '../../interfaces/MoanReport';


export const setSelectedMarkerIndex = (index: number | null) => {
    return { type: SET_MAP_SELECTED_MARKER, payload: index }
}

export const fetchedReports = (feed: Feed[]) => {
    return { type: FETCH_REPORTS, payload: feed }
}

export const filterReports = (feed: Feed[]) => {
    return { type: FILTER_REPORTS, payload: feed }
}

export const newReportsFromListener = (feed: Feed[]) => {
    return { type: NEW_REPORTS_FROM_LISTENER, payload: feed }
}

export const startFetchingReports = () => {
    return { type: START_FETCING_REPORTS }
}

export const startAddingReport = () => {
    return { type: START_ADDING_REPORT }
}
export const reportAdded = (report: MoanReport) => {
    return { type: REPORT_ADDED, payload: report }
}

export const clearReportAddingStatus = () => {
    return { type: CLEAR_REPORT_ADDING_STATUS }
}

export const addReport = (report: Report) => {

    return async (dispatch: any) => {
        try {
            dispatch({ type: START_ADDING_REPORT })
            //Add the report to firestore
            // const reportReference = firestore().collection('reportsIn');
            // await reportReference.add(report);
            const reportRes: any = await postReport(report)
            if (reportRes) {
                dispatch({ type: REPORT_ADDED, payload: reportRes.data.data })
            }

            setTimeout(() => {
                dispatch({ type: CLEAR_REPORT_ADDING_STATUS })
            }, 2500);
            /* DISPATCH REPORT? NEED TO GET REPORT ID FOR COMMENTING (+ LIKING?) AND TIMESTAMP */

        } catch (error) {
            console.log('Error adding a document: ', error);
        }
    };
};

export const handleLike = (id: string, liked: boolean) => {
    return async (dispatch: any) => {

        if (liked) {
            removeLike(id)
            dispatch({ type: REMOVE_LIKE, payload: { id: id } });
        } else {
            like(id)
            dispatch({ type: ADD_LIKE, payload: { id: id } });
        }
    };
};

export const fetchFeedUser = () => {
    return async (dispatch: any) => {

        let feed = await getUserFeed()
        if (feed) {
            dispatch({ type: FETCH_USER_REPORTS, payload: feed });
        }

    }
}
export const removeReport = (docId: string) => {
    return async (dispatch: any) => {
        try {
            //    const notificationAreaRef = firestore().doc(`reports/${docId}`)
            //   await notificationAreaRef.delete()
            console.log('todo implement')
            dispatch({ type: REMOVE_REPORT, payload: docId })
        } catch (e) {
        }
    }
}

export const setFromReportId = (docId: string | null) => {
    return { type: SET_REPORT_MARKER_ID, payload: docId }
}