enum Color {
    Pink = '#F14E69',
    LightPink = '#FFE6EA',
    LightPink50Op = 'rgba(255, 230, 234, 0.5)',
    TextPink = '#FF6079',
    DarkPink = '#6B5E60',
    White = '#FFFFFF',
    White70Op = 'rgba(255, 255, 255, 0.5)',
    White70 = '#464646',
    Black = '#232122',
    LightGrey = '#5A5757',
    DarkGrey = '#363233',
    Three = '#333333'
}

export default Color