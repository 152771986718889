import { AppState, NavigationState, SET_NAVIGATION_STATE } from './types';

const initialState: AppState = {
    navigationState: NavigationState.Loading
};

export const appReducer = (state = initialState, action: any): AppState => {
    switch (action.type) {
        case SET_NAVIGATION_STATE:
            return {
                ...state,
                navigationState: action.payload,
            };
        default:
            return state;
    }
};
