export interface AppState {
    navigationState: NavigationState,
}

export enum NavigationState {
    Loading,
    Main,
    Onboarding,
    Permissions
}

//Action strings
export const SET_NAVIGATION_STATE = 'SET_NAVIGATION_STATE'

interface SetNavigationStateActiopn {
    type: typeof SET_NAVIGATION_STATE;
    payload: NavigationState;
}

export type AppActionTypes = SetNavigationStateActiopn;
