import Feed from '../../interfaces/Feed';

export interface FeedState {
    feed: Feed[],
    userFeed: Feed[],
    userFeedTotalLikes: number,
    feedCached: Feed[],
    heatmap: {
        type: string;
        features: any[]
    }
    feedFetching: boolean,
    reportAdding: boolean,
    reportAdded: boolean,
    mapSelectedMarker: number | null
    fromReportId: string | null
}

//Action strings
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const ADD_USERCOMMENT = 'ADD_USERCOMMENT';
export const ADD_REPORT = 'ADD_REPORT';
export const ADD_LIKE = 'ADD_LIKE';
export const REMOVE_LIKE = 'REMOVE_LIKE';
export const FAILED_FETCHING_REPORTS = 'FAILED_FETCHING_REPORTS';
export const FAILED_ADDING_COMMENT = 'FAILED_ADDING_COMMENT';
export const FAILED_ADDING_LIKE = 'FAILED_ADDING_LIKE';
export const START_FETCING_REPORTS = 'START_FETCING_REPORTS';
export const NEW_REPORTS_FROM_LISTENER = 'NEW_REPORTS_FROM_LISTENER'
export const START_ADDING_REPORT = 'START_ADDING_REPORT'
export const REPORT_ADDED = 'REPORT_ADDED'
export const CLEAR_REPORT_ADDING_STATUS = 'CLEAR_REPORT_ADDING_STATUS'
export const SET_MAP_SELECTED_MARKER = 'SET_MAP_SELECTED_MARKER'
export const FILTER_REPORTS = 'FILTER_REPORTS'
export const FETCH_USER_REPORTS = 'FETCH_USER_REPORTS'
export const REMOVE_REPORT = 'REMOVE_REPORT'
export const SET_REPORT_MARKER_ID = 'SET_REPORT_MARKER_ID'

interface UpdateSessionAction {
    type: typeof FETCH_REPORTS;
    payload: Feed[];
}

interface NewReportsFromListenerAction {
    type: typeof NEW_REPORTS_FROM_LISTENER;
    payload: Feed[];
}

interface StartFetchingReportsAction {
    type: typeof START_FETCING_REPORTS;
}

interface SetMapSelectedMarkerAction {
    type: typeof SET_MAP_SELECTED_MARKER;
    payload: number;
}

export type FeedActionTypes = UpdateSessionAction | StartFetchingReportsAction | NewReportsFromListenerAction | SetMapSelectedMarkerAction;
