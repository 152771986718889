import Color from "../../styles/colors"
import styled from 'styled-components'

const BasicTextPink = styled.label`
font-family: "Nunito Sans", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
color: ${Color.TextPink};
font-variation-settings:
"wdth" 100,
"YTLC" 500;

`
export default BasicTextPink