import { LocationAccuracy } from "./locationAccuracys"

export const tenM: LocationAccuracy = {
    enabled: false,
    index: 0,
    label: '10m / 30ft',
    lengthM: 10,
    locationDecimals: 4,
    zoomLevel: 18

}

export const hundredM: LocationAccuracy = {
    enabled: true,
    index: 1,
    label: '100m / 300ft',
    lengthM: 100,
    locationDecimals: 3,
    zoomLevel: 16

}

export const oneKm: LocationAccuracy = {
    enabled: true,
    index: 2,
    label: '1km / 3000ft',
    lengthM: 1000,
    locationDecimals: 2,
    zoomLevel: 12

}

export const fiveKm: LocationAccuracy = {
    enabled: true,
    index: 3,
    label: '5km / 3mi',
    lengthM: 5000,
    locationDecimals: 2,
    zoomLevel: 10

}

export const tenKm: LocationAccuracy = {
    enabled: true,
    index: 4,
    label: '10km / 6mi',
    lengthM: 10000,
    locationDecimals: 1,
    zoomLevel: 9
}


export function getLocationAccuracy(value: string): LocationAccuracy {
    if (value === "10") {
        return tenM
    }
    if (value === "100") {
        return hundredM
    }
    if (value === "1000") {
        return oneKm
    }
    if (value === "5000") {
        return fiveKm
    }
    if (value === "10000") {
        return tenKm
    }
    return tenKm
}


export function getLocationAccuracyLabel(value: string): string {
    if (value === "10") {
        return "10 m"
    }
    if (value === "100") {
        return "100 m"
    }
    if (value === "1000") {
        return "1 km"
    }
    if (value === "5000") {
        return "5 km"
    }
    if (value === "10000") {
        return "10 km"
    }
    return "10 km"
}