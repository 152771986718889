import Color from "../styles/colors"
import styled from 'styled-components'
import ItalicHeading from "./Texts/ItalicHeading"
import ItalicHeadingWhite from "./Texts/ItalicHeadingWhite"
import UnderLineTextAction from "./Texts/UnderlineTextAction"
import { Select } from "./Select"
import BasicText from "./Texts/BasicText"
import { useDispatch, useSelector } from "react-redux"
import { selectTag } from "../store/tags/actions"
import { RootState } from "../store"

const OverlayFilter = styled.div`
z-index:5;
position: absolute;
bottom:0;
left:0;
right:0;
background-color:${Color.Black};
margin: 0.5em;
padding: 1em;
display:flex;
flex-direction:column;
overflow: hidden;
max-height: 80vh; 


`


const ContainerBtm = styled.div`
margin-bottom: 8px;
margin-top: 8px;
`

interface FilterProps {
    onTimeFilterChanged(minutes: number): void;
    onTagsChanged(): void;
    onClose(): void;
}


const TaglistTitle = styled.div`
margin-top: 16px;
margin-bottom: 4px;
`

const TagBox = styled.div`
  background-color: ${Color.DarkGrey};
  border-radius:6px;
  display: flex;
  flex-wrap:wrap;
  flex-direction:row;
`

const TagButton = styled.div`
  border-radius: 21px;
  margin: 10px;
  padding: 6px 15px;
  background-color: ${(props) => (props.active ? Color.Pink : 'transparent')};
  border-style: solid;
  border-color: ${(props) => (props.active ? Color.Pink : Color.LightPink)};
  border-width: 1px;
`

const TagSection = styled.div`
text-align:center;
padding-left: 1em;
padding-right:1em;
overflow-y: scroll;
`

export function Filter(props: FilterProps) {

    const parseSelect = (value: string) => {
        const number = parseInt(value)
        if (number === null) {
            return
        }
        props.onTimeFilterChanged(number)

    }
    const dispatch = useDispatch()
    const selectTags = (state: RootState) => state.tags;
    const tags = useSelector(selectTags);
    const listSourceName = 'curatedForFilters';

    const handleTagPress = (tag: any) => {
        dispatch(selectTag(tag.name, listSourceName))
        props.onTagsChanged()
    }


    return (
        <OverlayFilter>
            <div onClick={() => props.onClose()} style={{ textAlign: 'center', paddingBottom: 8, paddingLeft: 8, paddingRight: 8 }}>
                <UnderLineTextAction>Close</UnderLineTextAction>
            </div>

            <ContainerBtm>

                <ItalicHeadingWhite>Filter by tags:</ItalicHeadingWhite>
            </ContainerBtm>

            <TagSection>
                {tags.lists.map((list, index) => {
                    const tagsForList: any = [...tags.curatedForFilters.filter(tag => tag.list === list)]
                    const tagsSorted = tagsForList.sort((b, a) => (a.selected - b.selected))

                    return (
                        <div key={`list${list}`}>
                            <TaglistTitle>

                                <ItalicHeading>{list}</ItalicHeading>
                            </TaglistTitle>
                            <TagBox>
                                {tagsSorted.map((tag, index) => {
                                    if (tag.list === list) {

                                        return (
                                            <TagButton
                                                key={tag.name}
                                                active={tag.selected === true}
                                                onClick={() => handleTagPress(tag)}>
                                                <BasicText>{tag.name}</BasicText>
                                            </TagButton>
                                        );
                                    } else {
                                        return null
                                    }
                                })}
                            </TagBox>
                        </div>
                    );
                })}
            </TagSection>

            <ContainerBtm>

                <ItalicHeadingWhite>How Recent?</ItalicHeadingWhite>
            </ContainerBtm>

            <Select defaultValue={1440} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => parseSelect(event.target.value)}>
                <option value="15">15min</option>
                <option value="120">2h</option>
                <option value="1440">24h</option>
                <option value="10080">7d</option>
                <option value="0">all-time</option>
            </Select>

        </OverlayFilter>
    )
}