import Tag from '../../interfaces/Tag'
import { collection, doc, setDoc, getFirestore, getDocs, query, where } from 'firebase/firestore'
import { SET_CURATED_TAGS, SET_TAG_ACTIVE, RESET_TAG_SELECTIONS, ADD_COMMUNITY_TAG } from './types'
import { sortedUniq } from 'lodash'


export const fetchTags = (type: string) => {
    return async (dispatch: any) => {
        const db = getFirestore()
        const docRef = query(collection(db, `tags/${type}/tags`), where('list', '>', ''))
        const doc = await getDocs(docRef)
        let lists: string[] = []
        var tempTags: Tag[] = []
        doc.forEach(snap => {
            let { name, list } = snap.data()
            let tag: Tag = { name, list, selected: false }
            lists.push(list)
            tempTags.push(tag)
        })
        const listKeys = sortedUniq(lists)

        dispatch({ type: SET_CURATED_TAGS, payload: { type: type, tags: tempTags, lists: listKeys } })
    }
}

export const resetTagSelections = () => {
    return { type: RESET_TAG_SELECTIONS }
}

export const selectTag = (name: string, type: string) => {
    return { type: SET_TAG_ACTIVE, payload: { name: name, type: type } }
}