import styled from 'styled-components';
import Color from '../../styles/colors';

const ItalicHeadingBigPink = styled.label`
font-family: "IBM Plex Mono", monospace;
font-weight: 400;
font-style: italic;
font-size: 24px;
color: ${Color.Pink};
`

export default ItalicHeadingBigPink