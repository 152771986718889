import React from "react";
import Color from "../../styles/colors";

const MoanActivityIndicator = () => {

    
    return (

<span className="loader"></span>

    )
}

export default MoanActivityIndicator
