import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { tagsReducer } from './tags/reducers';
import { reportsReducer } from './reports/reducers';
import { userReducer } from './user/reducers';
import { appReducer } from './app/reducers';
import { formReducer } from './form/reducers';

const rootReducer = combineReducers({
  app: appReducer,
  form: formReducer,
  tags: tagsReducer,
  reports: reportsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const middlewares = [thunkMiddleware];
const middleWareEnhancer = applyMiddleware(...middlewares);

const store = createStore(
  rootReducer,
  composeWithDevTools(middleWareEnhancer),
)

export default store
