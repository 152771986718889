import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MapView } from './routes/Map';
import 'mapbox-gl/dist/mapbox-gl.css';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom"
import store from "./store";
import { Provider } from 'react-redux'
import { initializeApp } from "firebase/app";
import { Report } from './routes/Report';


function Expenses() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Expenses</h2>
    </main>
  );
}

function Invoices() {
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Invoices</h2>
    </main>
  );
}

const rootElement = document.getElementById("root");

render(
  <Provider store={store}>
    <HashRouter>
      <Routes>
        <Route path="expenses" element={<Expenses />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="" element={<MapView />} />
        <Route path="report" element={<Report />} />
      </Routes>
    </HashRouter>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


const firebaseConfig = {
  apiKey: "AIzaSyA2Kp4BJnrGaR_Tbu9FmQm8H9Y-6j9PMdc",
  authDomain: "moaner-9a6d7.firebaseapp.com",
  databaseURL: "https://moaner-9a6d7.firebaseio.com",
  projectId: "moaner-9a6d7",
  storageBucket: "moaner-9a6d7.appspot.com",
  messagingSenderId: "109543950714",
  appId: "1:109543950714:web:a6f2eb9e08ba27f286eec3",
  measurementId: "G-V7ESSVMWT8"
}

initializeApp(firebaseConfig)

reportWebVitals();
