import styled from 'styled-components';
import Color from '../../styles/colors';

const ItalicHeading = styled.label`
font-family: "IBM Plex Mono", monospace;
font-weight: 400;
font-style: italic;
color: ${Color.LightPink};
`

export default ItalicHeading