import { Location } from '../../interfaces/Location'
import { LocationAccuracy } from '../../utils/locationAccuracys'
import { scrambleLocation } from '../../utils/scrambleLocation'
import { SET_LOCATION, SET_SCRAMBLED_LOCATION } from './types'

export const setLocationToRedux = (location: any) => {
    const { latitude, longitude } = location.coords
    const realLocation: Location = {
        latitude, longitude
    }
    return { type: SET_LOCATION, payload: realLocation }
}

export const scrambleLocationRedux = (location: Location, locationAccuracy: LocationAccuracy) => {
    const accuracyM = locationAccuracy.lengthM
    const scrambledLocation = scrambleLocation(location, accuracyM)
    const payload = {
        location: scrambledLocation,
        locationAccuracy: locationAccuracy
    }

    return { type: SET_SCRAMBLED_LOCATION, payload }
}