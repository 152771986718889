import { httpsCallable, getFunctions } from 'firebase/functions'
import FetchRequest from '../interfaces/FetchRequest'
import MoanReport from '../interfaces/MoanReport';
import Report from '../interfaces/Report';
import { findIndex } from 'lodash';
const turf = require('@turf/turf')

interface NicknameChangeStatus {
    serverCode: string
    message: string
}

const nicknameChangeStatuses: NicknameChangeStatus[] = [
    {
        serverCode: 'error',
        message: 'Unknown error. Please try again.'
    },
    {
        serverCode: 'error-nick-taken',
        message: 'Nickname is already taken. :('
    },
    {
        serverCode: 'success',
        message: 'Nickname changed!'
    },
    {
        serverCode: 'error-no-purchase',
        message: 'No rights for nickname change. Please make a purchase first!'
    }
]

function getNicknameStatusMessage(serverCode: string) {
    let index = findIndex(nicknameChangeStatuses, { serverCode: serverCode })
    return nicknameChangeStatuses[index]
}

interface ReportFunctions {
    anonymous: boolean
    location: number[]
    locationAccuracy: number
    rating: number
    tags: string[]
}

function anonymizeLocation(coords: any) {
    return {
        latitude: anonymizeCoordinate(coords.latitude),
        longitude: anonymizeCoordinate(coords.longitude)
    }
}

function anonymizeCoordinate(coord: number) {
    return Number(coord.toFixed(2))
}


export async function sendFeedback(value: string) {
    try {
        //  await httpsCallable(name: 'sendFeedback')({ value: value })
        return true
    } catch (e) {
        console.log(e)
    }
}

export async function sendFeedbackTag(value: string) {
    try {
        //     await functions().httpsCallable('sendFeedbackTag')({ value: value })
        return true
    } catch (e) {
        console.log(e)
    }
}

export async function fetchFeed(fetchRequest: FetchRequest) {
    try {
        const functions = getFunctions();

        const res: any = await httpsCallable(functions, 'getFeed')(fetchRequest)
        const list: MoanReport[] = res.data.data
        return list
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function getUserFeed() {
    try {
        const functions = getFunctions();

        const res: any = await httpsCallable(functions, 'getUserFeed')()
        const list: MoanReport[] = res.data.data
        return list
    } catch (e) {
        console.log(e)
        return null
    }
}
export async function postReport(report: Report) {
    try {
        const functions = getFunctions();
        const { anonymous, locationAccuracy, rating, tags } = report
        const location = [report.location.latitude, report.location.longitude]
        const data: ReportFunctions = { anonymous, location, locationAccuracy, rating, tags }
        const res = await httpsCallable(functions, 'postReportWeb')(data)
        return res
    } catch (e) {
        return null
    }
}


export async function postReportWeb(report: Report) {
    try {
        const functions = getFunctions();
        const { anonymous, locationAccuracy, rating, tags } = report
        const location = [report.location.latitude, report.location.longitude]
        const data: ReportFunctions = { anonymous, location, locationAccuracy, rating, tags }
        const res = await httpsCallable(functions, 'postReportWeb')(data)
        return res
    } catch (e) {
        return null
    }
}

export function like(docId: string) {
    try {
        const functions = getFunctions();
        httpsCallable(functions, 'like')({ docId })
    } catch (e) {
        console.log(e)
    }
}

export function removeLike(docId: string) {
    try {
        const functions = getFunctions();
        httpsCallable(functions, 'removeLike')({ docId })
    } catch (e) {
        console.log(e)
    }
}


function createCircleFromGeoPoint(geopoint: any, radius: number) {
    const center = [geopoint._longitude, geopoint._latitude]
    return createCircle(center, radius)
}

function createCircle(center: any, radius: number) {
    var options = { steps: 64, units: 'meters', properties: { foo: 'bar' } };
    var circle = turf.circle(center, radius, options);
    return circle
}

function getRadiusTextKm(radius: number) {
    return `${(radius / 1000).toFixed(1)}`
}

function getRadiusTextMi(radius: number) {
    let radiusMi = radius * 0.6213
    return `${(radiusMi / 1000).toFixed(1)}`
}

function coordinateToFixed(number: number) {
    return parseFloat(number.toFixed(6))
}

async function checkLocationGrant() {
    //todo implement!
    return true
}



export {
    anonymizeLocation,
    coordinateToFixed,
    createCircle,
    createCircleFromGeoPoint,
    getRadiusTextKm,
    getRadiusTextMi,
    checkLocationGrant
}
