
import React, { useState } from 'react'
import styled from 'styled-components'
import Color from '../styles/colors';

const StarLabel = styled.label`
color: ${Color.Pink};
font-size: 1rem;
margin-right: 0.25em;
`

const StarInput = styled.input`
  display: none;

  &:checked {
    & ~ ${StarLabel} {
      color: #aaa;
    }
  }
`;

interface StarRatingProps {
  rating: number;
}

export function StarRatingReadOnly(props: StarRatingProps) {
  const rating = props.rating

  return (
    <div style={{ pointerEvents: 'none' }}>
      <StarInput type={'radio'} value='0' defaultChecked={rating === 0} id='star-0' name='rating' />

      <StarLabel htmlFor='star-1'>★</StarLabel>
      <StarInput type={'radio'} value='1' defaultChecked={rating === 1} id='star-1' name='rating' />

      <StarLabel htmlFor='star-2'>★</StarLabel>
      <StarInput type={'radio'} value='2' defaultChecked={rating === 2} id='star-2' name='rating' />

      <StarLabel htmlFor='star-3'>★</StarLabel>
      <StarInput type={'radio'} value='3' defaultChecked={rating === 3} id='star-3' name='rating' />

      <StarLabel htmlFor='star-4'>★</StarLabel>
      <StarInput type={'radio'} value='4' defaultChecked={rating === 4} id='star-4' name='rating' />

      <StarLabel htmlFor='star-5'>★</StarLabel>
      <StarInput type={'radio'} value='5' defaultChecked={rating === 5} id='star-5' name='rating' />

    </div>
  )
}