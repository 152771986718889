import styled from 'styled-components'
import Color from '../styles/colors'

export const Select = styled.select`
appearance: none; /* Remove default appearance */
-webkit-appearance: none; /* For older versions of webkit browsers */
-moz-appearance: none; /* For older versions of Firefox */
padding: 0.5rem 1rem; /* Add padding for better UX */
border: 1px solid #ccc; /* Add border for clarity */
border-radius: 4px; /* Add border radius for rounded corners */
background-color: #fff; /* Set background color */
font-size: 16px; /* Set font size */
color: ${Color.White};
font-family: "IBM Plex Mono", monospace;
font-weight: 400;
font-style: italic;
background-color:${Color.DarkGrey};
outline: none !important;
`
