import { UserState, FETCH_USER, SET_LIKED_DOCS } from './types';

const initialState: UserState = {
    user: {
        username: '',
        uid: '',
        unusedConsumableNick: false
    },
    likedDocs: []
};

export const userReducer = (state = initialState, action: any): UserState => {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_LIKED_DOCS:
            return {
                ...state,
                likedDocs: action.payload
            }
        default:
            return state;
    }
};
