import { Location } from '../../interfaces/Location';
import { FormState, FormStateActionTypes, SET_LOCATION, SET_SCRAMBLED_LOCATION } from './types';
import { LocationAccuracy, fiveKm, oneKm } from '../../utils/locationAccuracys';

const initialState: FormState = {
    locationAccuracy: fiveKm
};

export const formReducer = (state = initialState, action: FormStateActionTypes): FormState => {

    switch (action.type) {
        case SET_LOCATION:
            return {
                ...state,
                realLocation: action.payload,
            };
        case SET_SCRAMBLED_LOCATION:
            return {
                ...state,
                location: action.payload.location,
                locationAccuracy: action.payload.locationAccuracy
            }
        default:
            return state;
    }
};
