export async function getLocation(): Promise<{ latitude: number, longitude: number } | undefined> {
    if (!navigator.geolocation) {
        // Geolocation is not supported by the browser
        return undefined;
    }

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                resolve({ latitude, longitude });
            },
            (error) => {
                // An error occurred while obtaining the location
                if (error.code === error.PERMISSION_DENIED) {
                    // User denied permission to access location
                    resolve(undefined);
                } else {
                    // Location could not be obtained for some other reason
                    resolve(undefined);
                }
            }
        );
    });
}