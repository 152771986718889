import Color from "../styles/colors"
import styled from 'styled-components'
import BasicTextPink from "./Texts/BasicTextPink"
import Report from "../interfaces/Report"
import Feed from "../interfaces/Feed"
import moment from "moment"
import { StarRatingReadOnly } from "./StarRatingReadOnly"
import BasicText from "./Texts/BasicText"
import BasicTextWhite from "./Texts/BasicTextWhite"
import UnderLineTextAction from "./Texts/UnderlineTextAction"

const OverlaySelectedMarker = styled.div`
z-index:4;
position: absolute;
top:0;
left:0;
right:0;
background-color:${Color.Three};
margin: 0.5em;
padding: 1em;
`

const ContainerBtm = styled.div`
margin-bottom: 4px;
`

const TagContainer = styled.div`
background-color: ${Color.LightGrey};
border-radius: 21px;
margin: 10px;
padding: 6px 15px;
`

const Left = styled.div`
margin-left: 16px;
`


const TagBox = styled.div`
  background-color: ${Color.DarkGrey};
  border-radius:6px;
  display: flex;
  flex-wrap:wrap;
  flex-direction:row;
`
interface SelectedMarkerProps {
    feed: Feed;
    onClosePressed(): void;
}

export function SelectedMarker(props: SelectedMarkerProps) {
    const time = moment(props.feed.timestamp._seconds * 1000).fromNow()
    return (
        <OverlaySelectedMarker>
            <Left>

                <ContainerBtm>
                    <BasicTextPink>{time} </BasicTextPink>
                </ContainerBtm>
                <StarRatingReadOnly rating={props.feed.rating} />
            </Left>

            <ContainerBtm>
                <TagBox>
                    {props.feed.tags.map((tag, index) => {

                        return (
                            <TagContainer key={'T' + index}><BasicTextWhite>{tag}</BasicTextWhite></TagContainer>
                        )
                    })}
                </TagBox>
            </ContainerBtm>

            <div onClick={() => props.onClosePressed()} style={{ textAlign: 'center', paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}>
                <UnderLineTextAction>Close</UnderLineTextAction>
            </div>
        </OverlaySelectedMarker>
    )
}