import { RegionPayload } from '../interfaces/RegionPayload'
import * as turf from '@turf/turf'
import { fetchedReports, filterReports, startFetchingReports } from '../store/reports/actions';
import store from '../store'
import FetchRequest from '../interfaces/FetchRequest'
import MoanReport from '../interfaces/MoanReport';
import { fetchFeed } from '../utils/utils';
import { getNowReducedMinutes } from '../utils/time';
import Feed from '../interfaces/Feed';

const FETCH_NEXT_TIME_RADIUS = 1000 * 1000

export interface AreaChangedProps {
  northWest: number[],
  center: number[]
}

export class ReportFetchManager {

  fetchRequest: FetchRequest | undefined
  fetchedRequest: FetchRequest | undefined
  fetchNextTime: boolean
  fetchingRequest: boolean
  listeners: any[]
  maxAgeMinutes: number

  constructor() {
    this.fetchRequest = undefined
    this.fetchedRequest = undefined
    this.fetchingRequest = false
    this.fetchNextTime = false
    this.listeners = []
    this.maxAgeMinutes = 1440
  }

  timeFilterChanged(minutes: number) {

    this.maxAgeMinutes = minutes

    if (this.fetchedRequest === undefined) {
      return
    }

    const lastFetchMinutes = this.fetchedRequest.maxAgeMinutes

    if ((minutes > lastFetchMinutes || (minutes === 0 && minutes !== lastFetchMinutes)) && lastFetchMinutes !== 0) {
      //make a request
      const maxAgeMinutes = minutes
      const fetchRequest: FetchRequest = { ...this.fetchedRequest, maxAgeMinutes }
      this.setAndProcessFetchRequest(fetchRequest)
    } else {
      //allow filtering locally
      const minTimestamp = getNowReducedMinutes(minutes)
      const cachedFeed = store.getState().reports.feedCached
      let newFeed: Feed[] = []

      cachedFeed.forEach(item => {
        const timestamp = item.timestamp._seconds * 1000
        if (minutes === 0) {
          newFeed.push(item)
        } else if (timestamp >= minTimestamp) {
          newFeed.push(item)
        }
      })
      store.dispatch(filterReports(this.filterBySelectedTags(newFeed)))
    }

  }


  getFilteredTagsList(): string[] {
    const tagsArr = store.getState().tags.curatedForFilters

    const tagArr = tagsArr.map((tag) => {
      if (tag.selected) {
        return tag.name;
      }
    });
    let a: any = tagArr.filter((x) => x !== undefined);
    return a;
  }

  filterBySelectedTags(feed: Feed[]): Feed[] {
    const tags = this.getFilteredTagsList()
    console.log(tags)
    let newFeed: Feed[] = []

    for (const item of feed) {
      for (const tag of item.tags) {
        if (tags.includes(tag)) {
          newFeed.push(item)
          break
        }
      }
    }
    return newFeed
  }

  tagsChanged() {
    const cachedFeed = store.getState().reports.feedCached
    store.dispatch(filterReports(this.filterBySelectedTags(cachedFeed)))
  }

  setInitialPosition(position: GeoJSON.Position) {

    const topLeft: turf.Coord = [50, 50] //not used
    const distanceToTopLeft = 16000
    const centerForQuery = [position[1], position[0]]
    const maxAgeMinutes = this.maxAgeMinutes

    const fetchRequest: FetchRequest = { centerForQuery, distanceToTopLeft, topLeft, maxAgeMinutes }
    this.makeRequestIfRequired(fetchRequest)

  }

  areaChanged(e: AreaChangedProps) {
    const mapCenter = e.center

    const topLeft: turf.Coord = e.northWest

    const center: turf.Coord = e.center
    const centerForQuery = [mapCenter[1], mapCenter[0]]

    const distanceToTopLeft = turf.distance(topLeft, center, { units: 'meters' })
    const maxAgeMinutes = this.maxAgeMinutes

    const fetchRequest: FetchRequest = { centerForQuery, distanceToTopLeft, topLeft, maxAgeMinutes }
    console.log(fetchRequest)
    this.makeRequestIfRequired(fetchRequest)
  }

  makeRequestIfRequired(fetchRequest: FetchRequest) {
    /*
    Function checks if it's neccassary to make new request
    */

    if (this.fetchedRequest === undefined) {
      this.setAndProcessFetchRequest(fetchRequest)
      return
    }


    if (this.fetchedRequest !== undefined) {
      const fetchedCenter = this.fetchedRequest.centerForQuery
      const fetchedDistance = this.fetchedRequest.distanceToTopLeft
      const newCenter = fetchRequest.centerForQuery
      const newDistance = fetchRequest.distanceToTopLeft
      const newTopLeft = fetchRequest.topLeft
      const centerForTurf = [fetchedCenter[1], fetchedCenter[0]]
      const distanceToFetchedCenter = turf.distance(newTopLeft, centerForTurf, { units: 'meters' }) //calculate current top left distance from already fetched center

      const shouldMakeFetch = (distanceToFetchedCenter > fetchedDistance) // if current top left distance from center is smaller than already fetched center's distance, make request

      if (shouldMakeFetch || this.fetchNextTime) {
        this.setAndProcessFetchRequest(fetchRequest)
      }

    }


  }

  setAndProcessFetchRequest(fetchRequest: FetchRequest) {
    this.fetchRequest = fetchRequest
    if (!this.fetchingRequest) { //if it's not processing the request call function to process it
      this.processFetchRequestNew()
    }
  }

  async processFetchRequestNew() {

    if (this.fetchRequest === undefined) {
      return
    }
    /*
    Add request to this.fetchRequest
    so request can be added while
    its loading
    */

    store.dispatch(startFetchingReports())

    let feed = await fetchFeed(this.fetchRequest)
    if (feed) {
      this.processResponse(feed)
    }
  }

  processResponse(reports: MoanReport[]) {

    if (this.fetchRequest === undefined) {
      return
    }

    const userLikes = store.getState().user.likedDocs

    //Create a temporary array
    const tempFeed: any[] = [];

    reports.forEach(report => {

      let likes

      if (typeof report.likes !== 'number') {
        likes = 5
      } else {
        likes = report.likes
      }

      const newReport = {
        id: report.id,
        location: report.location,
        notes: '',
        rating: report.rating,
        timestamp: report.timestamp,
        tags: report.tags,
        comments: [],
        likes: likes,
        liked: userLikes.includes(report.id),
        username: report.username || 'Anonymous',
      };

      tempFeed.push(newReport);

    })
    //Dispatch the complete feed
    store.dispatch(fetchedReports(tempFeed))

    this.fetchNextTime = false

    if (tempFeed.length === 0) {
      this.fetchNextTime = true
    }

    if (this.fetchRequest.distanceToTopLeft > FETCH_NEXT_TIME_RADIUS) {
      this.fetchNextTime = true
    }

    //Mark request as fetched
    this.fetchedRequest = this.fetchRequest

  }

  clearListeners() {

    for (const index in this.listeners) {
      const listener = this.listeners[index]
      listener() // to detach it
    }

    this.listeners = []

  }

}
