import { Location } from "../interfaces/Location"

function getRandomNum() {
    // return (2 * Math.random() - 1)
    return Math.random()
}

function scrambleLocation(coords: Location, accuracyM: number) {
    const PI = Math.PI
    const RADIUS_OF_EARTH = 6371000
    const ONE_BY_180 = 1 / 180

    const { latitude, longitude } = coords
    const dLat = PI * RADIUS_OF_EARTH * ONE_BY_180
    const dLon = Math.cos(latitude) * PI * RADIUS_OF_EARTH * ONE_BY_180

    const maxForLat = (accuracyM / dLat)
    const maxForLon = (accuracyM / dLon)

    const randForLat = getRandomNum()
    const randForLon = getRandomNum()

    const scrambledLocation: Location = {
        latitude: (latitude + (2 * randForLat - 1) * maxForLat),
        longitude: (longitude + (2 * randForLon - 1) * maxForLon),
    }
    return scrambledLocation
}

export {
    scrambleLocation
}