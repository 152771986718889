import { Map as MapType } from 'mapbox-gl';
import * as React from 'react';
import { useEffect, useRef, useMemo } from 'react';
import Map, { Layer, Source } from 'react-map-gl';
import { AreaChangedProps, ReportFetchManager } from '../helpers/ReportFetchManager';
import styled from 'styled-components'
import Color from '../styles/colors';
import { heatmapLayer } from '../styles/heatmapLayer';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import MoanActivityIndicator from '../components/MoanActivityIndicator/MoanActivityIndicator';
import { getLocation } from '../helpers/geolocation';
import { setLocationToRedux } from '../store/form/actions';
import { useNavigate } from 'react-router-dom';
import { fetchTags } from '../store/tags/actions';
import { findFeatureByCoordinates } from '../utils/lodash';
import { setFromReportId, setSelectedMarkerIndex } from '../store/reports/actions';
import { ReportOverlay } from '../components/ReportOverlay';
import BasicText from '../components/Texts/BasicText';
import BasicTextPink from '../components/Texts/BasicTextPink';
import { SelectedMarker } from '../components/SelectedMarker';
import Report from '../interfaces/Report';
import Feed from '../interfaces/Feed';
import { Filter } from '../components/Filter';


const FullScreenView = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
    margin: 0 auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
overflow-y: auto;
  `


const LoadingBar = styled.div`
border-radius: 22px;
background-color: ${props => props.transparent ? 'transparent' : Color.LightPink};
height:50px;
width:200px;
display:flex;
align-self:center;
justify-content:center;
align-items:center;
margin-top:10px;
`

const OverlayButtons = styled.div`
pointer-events: none;
position: absolute;
top:0;
bottom:0;
left:0;
right:0;
z-index:3;
margin-bottom: 72px;
display:flex;
flex-direction: column;
align-items:center;
justify-content: space-between;
`


const OverlayBottomButtons = styled.div`
pointer-events: auto;
position: absolute;
bottom:0;
right:0;
z-index: 4;
display:flex;
flex-direction: column;
align-items:flex-end;
justify-content: flex-end;

`
const circleLayerStyle: any = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 20,
    'circle-color': Color.Pink
  }
};

export function MapView() {

  const navigate = useNavigate()
  const selectFeed = (state: RootState) => state.reports.feed
  const selectHeatmap = (state: RootState) => state.reports.heatmap
  const heatmap: any = useSelector(selectHeatmap)
  const feed = useSelector(selectFeed)
  const selectSelectedMarkerId = (state: RootState) => state.reports.mapSelectedMarker
  const selectedMarkerId = useSelector(selectSelectedMarkerId)
  const [filterVisible, setFilterVisible] = React.useState(false)
  const selectFromReportId = (state: RootState) => state.reports.fromReportId
  const reportId = useSelector(selectFromReportId)

  const selectedMarker = useMemo((): Feed | null => {
    if (selectedMarkerId === null) {
      return null
    }
    return feed[selectedMarkerId]
  }, [selectedMarkerId])


  const selectFeedFetching = (state: RootState) => state.reports.feedFetching

  const feedFetching = useSelector(selectFeedFetching)
  const [locating, setLocating] = React.useState(false)
  const dispatch = useDispatch()

  const markerFromReport = useMemo((): Feed | null => {
    if (feed.length === 0) {
      return null
    }
    if (!selectFromReportId) {
      return null
    }
    const item = feed.find((item) => item.id === reportId)
    if (!item) {
      return null
    }
    return item
  }, [feed])

  /*
     <MapComponent
    initialViewState={{
      longitude: 24,
      latitude: 65,
      zoom: 12
    }}
    mapboxAccessToken={'pk.eyJ1IjoibWlra29hdGEiLCJhIjoiY2s4NGh0cnR4MHJkczNncXRkNmJuN2NnaCJ9.kTqiQYAWc0kCFBPznJ2vKA'}
    style={{width: 1000, height: 1000}}
    mapStyle="mapbox://styles/mikkoata/ckh2gun9d1o8v19r0b0ec85gb"
  />
  */
  const reportFetchHelper: any = useRef()
  const mapContainer: any = useRef()

  useEffect(() => {
    if (!markerFromReport) {
      return
    }

    let mapElement: MapType = mapContainer.current
    mapElement.setCenter([markerFromReport.location._longitude, markerFromReport.location._latitude])
    mapElement.setZoom(16)
    const index = feed.findIndex((item) => item.id === reportId)
    dispatch(setSelectedMarkerIndex(index))
    dispatch(setFromReportId(null))
  }, [markerFromReport])


  useEffect(() => {
    reportFetchHelper.current = new ReportFetchManager()

    setTimeout(() => {
      // reportFetchHelper.current.tagsChanged(['Male'])
    }, 4000);
    dispatch(fetchTags('curated'))
    return () => {
      reportFetchHelper.current.clearListeners()
    }


  }, [])

  const handleOnMoveEnd = () => {
    if (!mapContainer.current) {
      return
    }

    let mapElement: MapType = mapContainer.current
    let nw = mapElement.getBounds().getNorthWest()
    let center = mapElement.getCenter()

    let moveEnd: AreaChangedProps = {
      northWest: [nw.lng, nw.lat],
      center: [center.lng, center.lat]
    }
    reportFetchHelper.current.areaChanged(moveEnd)
  }

  const onLocateError = () => {
    setLocating(false)
    alert('Location failed')
  }

  const navigateToReport = async () => {
    try {
      setLocating(true)
      const location = await getLocation()
      if (!location) {
        return onLocateError()
      }
      setLocating(false)
      dispatch(setLocationToRedux({ coords: location }))
      navigate('/Report')

      console.log(location)
    } catch (e) {
      console.log(e)
      onLocateError()
    }
  }

  const handleMapOnClick = (e: mapboxgl.MapMouseEvent) => {
    setFilterVisible(false)
    const coordinates = [e.lngLat.lng, e.lngLat.lat]
    const f = findFeatureByCoordinates(heatmap.features, coordinates)
    dispatch(setSelectedMarkerIndex(f))
  }

  return (
    <FullScreenView>
      {filterVisible ? (
        <Filter onClose={() => setFilterVisible(false)} onTimeFilterChanged={(minutes) => reportFetchHelper.current.timeFilterChanged(minutes)} onTagsChanged={() => reportFetchHelper.current.tagsChanged()} />
      ) : null}
      {selectedMarker ? (
        <SelectedMarker feed={selectedMarker} onClosePressed={() => dispatch(setSelectedMarkerIndex(null))} />
      ) : null}
      <OverlayButtons>

        {(feedFetching || locating) ? (


          <LoadingBar>
            <MoanActivityIndicator />

          </LoadingBar>
        ) : null}


        <OverlayBottomButtons>
          <div style={{ marginRight: 20 }} onClick={() => setFilterVisible(true)}>
            <img src='./images/filter2x.png' width={72} height={72} />
          </div>
          <div style={{ marginRight: 20 }} onClick={() => navigateToReport()}>
            <img src='./images/newMoan2x.png' width={98} height={98} />
          </div>



        </OverlayBottomButtons>

      </OverlayButtons>
      <Map
        initialViewState={{
          longitude: 24.9963648,
          latitude: 60.3148618,
          zoom: 8
        }}
        onClick={(e) => handleMapOnClick(e)}
        ref={el => (mapContainer.current = el)}
        onZoomEnd={e => { handleOnMoveEnd() }}
        onDragEnd={e => { handleOnMoveEnd() }}
        onLoad={e => { handleOnMoveEnd() }}
        style={{ flex: 1 }}
        mapboxAccessToken={'pk.eyJ1IjoibWlra29hdGEiLCJhIjoiY2s4NGh0cnR4MHJkczNncXRkNmJuN2NnaCJ9.kTqiQYAWc0kCFBPznJ2vKA'}
        mapStyle="mapbox://styles/mikkoata/ckh2gun9d1o8v19r0b0ec85gb"
      >





        <Source type="geojson" data={heatmap}>
          <Layer {...heatmapLayer} id="heatmaps" />
        </Source>

        {markerFromReport ? (
          <Source id="my-data" type="geojson" data={
            {
              type: 'FeatureCollection',
              features: [
                { type: 'Feature', geometry: { type: 'Point', coordinates: [markerFromReport.location._longitude, markerFromReport.location._latitude] } } as any
              ]
            }
          }>
            <Layer {...circleLayerStyle} />
          </Source>

        ) : null}

      </Map>
    </FullScreenView>

  )

  return (
    <div>
      <h1>moi</h1>

    </div>)

}