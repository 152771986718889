export interface LocationAccuracy {
    index: number,
    enabled: boolean,
    label: string,
    lengthM: number,
    locationDecimals: number
    zoomLevel: number
}

const oneM: LocationAccuracy = {
    enabled: false,
    index: 0,
    label: '1m',
    lengthM: 1,
    locationDecimals: 5,
    zoomLevel: 20

}

const tenM: LocationAccuracy = {
    enabled: false,
    index: 0,
    label: '10m / 30ft',
    lengthM: 10,
    locationDecimals: 4,
    zoomLevel: 18

}

const hundredM: LocationAccuracy = {
    enabled: true,
    index: 1,
    label: '100m / 300ft',
    lengthM: 100,
    locationDecimals: 3,
    zoomLevel: 16

}

export const oneKm: LocationAccuracy = {
    enabled: true,
    index: 2,
    label: '1km / 3000ft',
    lengthM: 1000,
    locationDecimals: 2,
    zoomLevel: 12

}

export const fiveKm: LocationAccuracy = {
    enabled: true,
    index: 3,
    label: '5km / 3mi',
    lengthM: 5000,
    locationDecimals: 2,
    zoomLevel: 10

}

const tenKm: LocationAccuracy = {
    enabled: true,
    index: 4,
    label: '10km / 6mi',
    lengthM: 10000,
    locationDecimals: 1,
    zoomLevel: 9
}

const allKm: LocationAccuracy = {
    enabled: true,
    index: 5,
    label: 'All (default)',
    lengthM: 0,
    locationDecimals: 0,
    zoomLevel: 9
}

export const locationAccuracySelections = [tenM, hundredM, oneKm, fiveKm, tenKm]
export const locationAccuracySelectionsNotifications = [hundredM, oneKm, fiveKm, tenKm, allKm]