import * as turf from '@turf/turf'
// Function to round numbers to specified decimal places
function roundNumber(num, decimals) {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
}

function roundCoordinate(num) {
    return roundNumber(num, 3)
}

export function findFeatureByCoordinates(features, searchCoordinates) {

    const targetPoint = turf.point([searchCoordinates[0], searchCoordinates[1]]);
    const nearest = turf.nearestPoint(targetPoint, turf.featureCollection(features));
    return nearest.properties.index
}
