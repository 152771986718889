import Tag from '../../interfaces/Tag'

// Describing the shape of the system's slice of state
export interface TagsState {
  lists: string[],
  curated: Tag[],
  curatedForFilters: Tag[],
  community: Tag[]
}

// Describing the different ACTION NAMES available
export const UPDATE_SESSION = "UPDATE_SESSION";
export const SET_CURATED_TAGS = 'SET_CURATED_TAGS'
export const SET_TAG_ACTIVE = 'SET_TAG_ACTIVE'
export const RESET_TAG_SELECTIONS = 'RESET_TAG_SELECTIONS'
export const ADD_COMMUNITY_TAG = 'ADD_COMMUNITY_TAG'

interface UpdateSessionAction {
  type: typeof SET_CURATED_TAGS;
  payload: Tag[];
}

export type TagsActionTypes = UpdateSessionAction;
