import Feed from "../interfaces/Feed";
import { createCircle, createCircleFromGeoPoint } from '../utils/utils';
import { Location } from "../interfaces/Location";

function getFeatureCollectionForSingleLocation(location: Location) {
    let featureCollection = {
        "type": "FeatureCollection",
        "features": [] as any
    }

    let feature = getPointByLocation(location)
    featureCollection.features.push(feature)
    return featureCollection
}


function getFeatureCollectionForReports(reports: Feed[]) {
    let featureCollection = {
        "type": "FeatureCollection",
        "features": [] as any
    }

    reports.forEach((report, index) => {
        let feature = getPointFeed(report.location, index)
        featureCollection.features.push(feature)
    })
    return featureCollection
}

function getPointByLocation(location: Location) {
    return {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                location.longitude,
                location.latitude
            ],
        },
    }
}

function getPoint(location: any, docId: string) {
    return {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                location._longitude,
                location._latitude
            ],
        },
        "properties": {
            "docId": docId
        }
    }
}

function getPointFeed(location: any, index: number) {

    return {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                location._longitude,
                location._latitude
            ],
        },
        "properties": {
            "index": index
        }
    }
}

const emptyFeatureCollection = {
    "type": "FeatureCollection",
    "features": []
}

const emptyFeature = {
    "type": "Feature",
    "geometry": {
        "type": "Point",
        "coordinates": []
    }
}

export {
    getFeatureCollectionForReports,
    getFeatureCollectionForSingleLocation,
    emptyFeatureCollection,
    emptyFeature
}