import User from '../../interfaces/User';

export interface UserState {
    user: User,
    likedDocs: string[]
}

//Action strings
export const FETCH_USER = 'FETCH_USER';
export const SET_LIKED_DOCS = 'SET_LIKED_DOCS';

interface UpdateSessionAction {
    type: typeof FETCH_USER;
    payload: User;
}
interface SetLikedDocsAction {
    type: typeof SET_LIKED_DOCS;
    payload: string[]
}

export type UserActionTypes = UpdateSessionAction | SetLikedDocsAction;
