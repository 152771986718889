
import React, { useState } from 'react'
import styled from 'styled-components'
import Color from '../styles/colors';

const StarLabel = styled.label`
color: ${Color.Pink};
font-size: 2rem;
margin-right: 0.25em;
`

const StarInput = styled.input`
  display: none;

  &:checked {
    & ~ ${StarLabel} {
      color: #aaa;
    }
  }
`;

interface StarRatingProps {
  onRatingChange(number: number): void;
}

export function StarRating(props: StarRatingProps) {
  const [rating, _setRating] = useState(0);
  const setRating = (rating: number) => {
    _setRating(rating)
    props.onRatingChange(rating)
  }

  return (
    <div>
      <StarInput type={'radio'} value='0' defaultChecked id='star-0' name='rating' onChange={() => setRating(0)} />

      <StarLabel htmlFor='star-1'>★</StarLabel>
      <StarInput type={'radio'} value='1' id='star-1' name='rating' onChange={() => setRating(1)} />

      <StarLabel htmlFor='star-2'>★</StarLabel>
      <StarInput type={'radio'} value='2' id='star-2' name='rating' onChange={() => setRating(2)} />

      <StarLabel htmlFor='star-3'>★</StarLabel>
      <StarInput type={'radio'} value='3' id='star-3' name='rating' onChange={() => setRating(3)} />

      <StarLabel htmlFor='star-4'>★</StarLabel>
      <StarInput type={'radio'} value='4' id='star-4' name='rating' onChange={() => setRating(4)} />

      <StarLabel htmlFor='star-5'>★</StarLabel>
      <StarInput type={'radio'} value='5' id='star-5' name='rating' onChange={() => setRating(5)} />

    </div>
  )
}