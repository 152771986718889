import styled from 'styled-components'
import Color from '../../styles/colors'

const ButtonView = styled.div`
    padding: 16px 64px;
    background-color: ${Color.Pink};
    justify-content: center;
    align-items:center;
    border-radius: 999px;
    text-align: center;
`

const ButtonText = styled.label`
    font-size: 25px;
    flex:1;
    font-family: 'IBM Plex Mono', monospace;
    font-style: italic;
    color: ${Color.White}
`

interface ButtonProps {
    title: string
    onPress: any
    small?: boolean
    disabled?: boolean
}

const Button = (props: ButtonProps) => {
    return (
        <ButtonView disabled={props.disabled} onClick={props.onPress} small={props.small}>
            <ButtonText numberOfLines={1} >{props.title}</ButtonText>
        </ButtonView>
    )
}

export default Button