import { FETCH_REPORTS, ADD_USERCOMMENT, FeedState, ADD_REPORT, ADD_LIKE, REMOVE_LIKE, START_FETCING_REPORTS, NEW_REPORTS_FROM_LISTENER, START_ADDING_REPORT, REPORT_ADDED, CLEAR_REPORT_ADDING_STATUS, SET_MAP_SELECTED_MARKER, FILTER_REPORTS, FETCH_USER_REPORTS, REMOVE_REPORT, SET_REPORT_MARKER_ID } from './types';
import { uniqBy, sortBy } from 'lodash'
import { getFeatureCollectionForReports } from '../../utils/geojson';

const initialState: FeedState = {
    feed: [],
    feedCached: [],
    userFeed: [],
    userFeedTotalLikes: 0,
    heatmap: {
        type: "FeatureCollection",
        features: []
    },
    feedFetching: false,
    reportAdding: false,
    reportAdded: false,
    mapSelectedMarker: null,
    fromReportId: null
};

export const reportsReducer = (state = initialState, action: any): FeedState => {
    switch (action.type) {
        case SET_MAP_SELECTED_MARKER:
            return {
                ...state,
                mapSelectedMarker: action.payload
            }
        case FETCH_REPORTS:
            let newFeed = sortBy(action.payload, 'timestamp._seconds').reverse()
            let newHeatmap = getFeatureCollectionForReports(newFeed)

            return {
                ...state,
                feedFetching: false,
                feed: newFeed,
                feedCached: newFeed,
                heatmap: newHeatmap
            };

        case FETCH_USER_REPORTS:
            let newUserFeed = sortBy(action.payload, 'timestamp._seconds').reverse()
            let userFeedTotalLikes = 0
            newUserFeed.forEach(report => {
                if (report.likes > 0) {
                    userFeedTotalLikes += report.likes
                }
            })
            return {
                ...state,
                userFeed: newUserFeed,
                userFeedTotalLikes: userFeedTotalLikes
            };


        case FILTER_REPORTS:
            let newFeedFiltered = sortBy(action.payload, 'timestamp._seconds').reverse()
            let newHeatmapFiltered = getFeatureCollectionForReports(newFeedFiltered)

            return {
                ...state,
                feedFetching: false,
                feed: newFeedFiltered,
                heatmap: newHeatmapFiltered
            };

        case ADD_REPORT:
            return {
                ...state,
                feed: [
                    ...state.feed,
                    action.payload,
                ],
            };
        case NEW_REPORTS_FROM_LISTENER:

            let mergedArrays = [...state.feed, ...action.payload]
            let mergedUniq = uniqBy(mergedArrays, 'id')
            let feed = sortBy(mergedUniq, 'timestamp.seconds').reverse()

            return {
                ...state,
                feed
            }
        case ADD_USERCOMMENT:
            return {
                ...state,
                feed: state.feed.map(report => {
                    if (report.id === action.payload.id) {
                        return {
                            ...report,
                            comments: [
                                ...report.comments,
                                action.payload.comment,
                            ],
                        };
                    }
                    return report;
                })
            };
        case ADD_LIKE:
            return {
                ...state,
                feed: state.feed.map(report => {
                    if (report.id === action.payload.id) {
                        let newLikes = report.likes + 1
                        return {
                            ...report,
                            liked: true,
                            likes: newLikes
                        };
                    }
                    return report;
                }),
                feedCached: state.feedCached.map(report => {
                    if (report.id === action.payload.id) {
                        let newLikes = report.likes + 1
                        return {
                            ...report,
                            liked: true,
                            likes: newLikes
                        };
                    }
                    return report;
                })
            };
        case REMOVE_LIKE:
            return {
                ...state,
                feed: state.feed.map(report => {
                    if (report.id === action.payload.id) {
                        let newLikes = report.likes - 1

                        return {
                            ...report,
                            liked: false,
                            likes: newLikes,
                        };
                    }
                    return report;
                }),
                feedCached: state.feedCached.map(report => {
                    if (report.id === action.payload.id) {
                        let newLikes = report.likes - 1

                        return {
                            ...report,
                            liked: false,
                            likes: newLikes,
                        };
                    }
                    return report;
                })
            };
        case START_FETCING_REPORTS:
            return {
                ...state,
                feedFetching: true
            }
        case START_ADDING_REPORT:
            return {
                ...state,
                reportAdded: false,
                reportAdding: true
            }
        case REPORT_ADDED:

            if (action.payload.username === null) {
                action.payload.username = 'Anonymous'
            }

            let feedAfterAdding = [action.payload, ...state.feed]
            let userFeedAfterAdding = [action.payload, ...state.userFeed]
            let feedCachedAfterAdding = [action.payload, ...state.feedCached]
            let heatMapAfterAdding = getFeatureCollectionForReports(feedAfterAdding)

            return {
                ...state,
                reportAdding: false,
                reportAdded: true,
                feed: feedAfterAdding,
                userFeed: userFeedAfterAdding,
                feedCached: feedCachedAfterAdding,
                heatmap: heatMapAfterAdding
            }
        case CLEAR_REPORT_ADDING_STATUS:
            return {
                ...state,
                reportAdded: false,
                reportAdding: false
            }
        case REMOVE_REPORT:
            return {
                ...state,
                feed: state.feed.filter(report => report.id !== action.payload),
                feedCached: state.feedCached.filter(report => report.id !== action.payload),
                userFeed: state.userFeed.filter(report => report.id !== action.payload)
            }
        case SET_REPORT_MARKER_ID:
            return {
                ...state,
                fromReportId: action.payload
            }

        default:
            return state;
    }
};
