import type {HeatmapLayer} from 'react-map-gl';


export const heatmapLayer: HeatmapLayer = {
  id: 'heatmap',
  type: 'heatmap',
  paint: {

    'heatmap-weight': [
      "interpolate",
      ["linear"],
      ["zoom"],
      0,
      0.5,
      2,
      1,
      22,
      1
    ],

    'heatmap-intensity': [
      "interpolate",
      ["linear"],
      ["zoom"],
      0,
      1,
      2,
      4,
      22,
      3
    ],

    'heatmap-color': [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(0, 0, 255, 0)",
      0.1,
      "hsla(311, 100%, 86%, 0.25)",
      0.3,
      "hsla(331, 98%, 93%, 0.5)",
      0.5,
      "hsl(317, 94%, 94%)",
      0.6,
      "hsla(350, 98%, 86%, 0.63)",
      0.7,
      "hsl(350, 95%, 76%)",
      0.8,
      "hsl(350, 96%, 80%)",
      0.9,
      "hsl(331, 85%, 63%)",
      1,
      "hsl(0, 92%, 57%)"
  
    ],
    'heatmap-radius': [
        "interpolate",
        ["cubic-bezier", 1, 1, 1, 1],
        ["zoom"],
        0,
        5,
        2,
        5,
        3,
        4,
        4,
        4,
        5,
        5,
        10,
        15,
        22,
        20
  ],
  }
};