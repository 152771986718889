import { Location } from "../../interfaces/Location";
import { LocationAccuracy, oneKm } from '../../utils/locationAccuracys';

export interface FormState {
    realLocation?: Location,
    location?: Location,
    locationAccuracy: LocationAccuracy
}



export const SET_LOCATION = 'SET_LOCATION'
export const SET_SCRAMBLED_LOCATION = 'SET_SCRAMBLED_LOCATION'

interface SetLocationAction {
    type: typeof SET_LOCATION;
    payload: Location;
}

interface ScramledLocationPayload {
    location: Location,
    locationAccuracy: LocationAccuracy
}
interface SetScrambledLocationAction {
    type: typeof SET_SCRAMBLED_LOCATION;
    payload: ScramledLocationPayload
}

export type FormStateActionTypes = SetLocationAction | SetScrambledLocationAction;
