import styled from 'styled-components';
import Color from '../../styles/colors';

const UnderLineTextAction = styled.label`
font-family: "Nunito Sans", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
color: ${Color.White};
text-decoration: underline;
font-variation-settings:
"wdth" 100,
"YTLC" 500;

`

export default UnderLineTextAction