import React, { useEffect, useState } from 'react';
import { StarRating } from '../components/StarRating';
import styled from 'styled-components'
import Color from '../styles/colors';
import ItalicHeading from '../components/Texts/ItalicHeading';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { selectTag } from '../store/tags/actions';
import BasicText from '../components/Texts/BasicText';
import Button from '../components/Buttons/Button';
import { scrambleLocationRedux } from '../store/form/actions';
import { useNavigate } from 'react-router-dom';
import { postReportWeb } from '../utils/utils';
import { Select } from '../components/Select';
import { locationAccuracySelections } from '../utils/locationAccuracys';
import { getLocationAccuracy, getLocationAccuracyLabel } from '../utils/singleLocationAccuracys';
import MoanActivityIndicator from '../components/MoanActivityIndicator/MoanActivityIndicator';
import { setFromReportId } from '../store/reports/actions';
import Map, { Layer, ScaleControl, Source } from 'react-map-gl';
import BasicTextWhite from '../components/Texts/BasicTextWhite';
import ItalicHeadingBigPink from '../components/Texts/ItalicHeadingBigPink';

const ReportBase = styled.div`
background-color: ${Color.Black};
`

const InnerContainer = styled.div`
display: flex;
justify-content: flex-end;
padding-top: 2em;
min-height: 100vh;
align-items: center;
flex-direction: column;
`

const InnerContainerNoTop = styled.div`
display: flex;
justify-content: flex-end;
min-height: 100vh;
align-items: center;
flex-direction: column;
`

const LoaderContainer = styled.div`
display: flex;
justify-content: center;
min-height: 100vh;
align-items: center;
flex-direction: column;

`

const LogoContainer = styled.div`
margin-bottom: 1em;
`

const TaglistTitle = styled.div`
margin-top: 16px;
margin-bottom: 4px;
`

const TagBox = styled.div`
  background-color: ${Color.DarkGrey};
  border-radius:6px;
  display: flex;
  flex-wrap:wrap;
  flex-direction:row;
`

const TagButton = styled.div`
  border-radius: 21px;
  margin: 10px;
  padding: 6px 15px;
  background-color: ${(props) => (props.active ? Color.Pink : 'transparent')};
  border-style: solid;
  border-color: ${(props) => (props.active ? Color.Pink : Color.LightPink)};
  border-width: 1px;
`

const TagSection = styled.div`
text-align:center;
padding-left: 1em;
padding-right:1em;

`

const ContainerBtm = styled.div`
margin-bottom: 4px;
`

const ContainerAccuracy = styled.div`
margin-top: 16px;
margin-bottom:16px;
`




export function Report() {


    const [loading, setLoading] = useState(false)
    const selectTags = (state: RootState) => state.tags;
    const tags = useSelector(selectTags);
    const selectForm = (state: RootState) => state.form;
    const form = useSelector(selectForm)
    const locationAccuracy = form.locationAccuracy

    const [locAForMap, setLocAForMap] = useState(100)
    const [locationNowLabel, setLocationNowLabel] = useState("100 m")

    const [rating, setRating] = React.useState(0)
    const [setLocationAccuracy, setSetLocationAccuracy] = React.useState(false)
    const listSourceName = 'curated';
    const navigate = useNavigate()

    const divide = 50
    const circleLayerStyle: any = {
        id: 'point',
        type: 'circle',
        paint: {
            'circle-radius': locAForMap / divide,
            'circle-color': Color.Pink,
            'circle-opacity': 0.70

        }
    };


    useEffect(() => {
        console.log(`Rating on ${rating}`)
    }, [rating]);
    const dispatch = useDispatch()

    useEffect(() => {
        if (form.realLocation === undefined) {
            alert('Locating failed!!')
            navigate('/')

            return
        }
        dispatch(scrambleLocationRedux(form.realLocation, getLocationAccuracy("100")))
    }, [])

    function getSelectedTagList() {
        const curatedArr = tags.curated;
        const tagsArr = curatedArr.concat(tags.community);

        const tagArr = tagsArr.map((tag) => {
            if (tag.selected) {
                return tag.name;
            }
        });
        return tagArr.filter((x) => x !== undefined); //removes undefined values
    }

    const isFormValid = (): boolean => {
        if (!form.location) {
            return false
        }
        if (rating === 0) {
            return false
        }
        return true
    }

    const postReport = () => {
        let report: any = {
            rating: rating,
            anonymous: true,
            location: form.location,
            locationAccuracy: form.locationAccuracy.lengthM,
            timestamp: 'null',
            uid: 'ano',
            tags: getSelectedTagList()

        }
        setLoading(true)
        postReportWeb(report).then((response) => {
            const res: any = response?.data
            const id = res.data.id
            console.log(id)
            dispatch(setFromReportId(id))
            navigate('/')
        })

    }

    const validateAndPostReport = () => {
        if (!isFormValid()) {
            alert('For moan fill at least rating!')
        }
        postReport()
    }



    const handleAccuracyChange = (value: string) => {
        const locationAccuracy = getLocationAccuracy(value)
        const label = getLocationAccuracyLabel(value)
        setLocationNowLabel(label)
        if (form.realLocation) {
            dispatch(scrambleLocationRedux(form.realLocation, locationAccuracy))
            setLocAForMap(locationAccuracy.lengthM)
        }
    }

    if (loading) {
        return <ReportBase>
            <LoaderContainer>

                <MoanActivityIndicator />
            </LoaderContainer>
        </ReportBase>
    }

    if (setLocationAccuracy) {
        return (
            <ReportBase>
                <InnerContainerNoTop>
                    <Map
                        initialViewState={{
                            longitude: form.realLocation?.longitude,
                            latitude: form.realLocation?.latitude,
                            zoom: 11
                        }}
                        style={{ flex: 1, pointerEvents: 'none' }}
                        mapboxAccessToken={'pk.eyJ1IjoibWlra29hdGEiLCJhIjoiY2s4NGh0cnR4MHJkczNncXRkNmJuN2NnaCJ9.kTqiQYAWc0kCFBPznJ2vKA'}
                        mapStyle="mapbox://styles/mikkoata/ckh2gun9d1o8v19r0b0ec85gb"
                    >
                        <Source id="my-data" type="geojson" data={
                            {
                                type: 'FeatureCollection',
                                features: [
                                    { type: 'Feature', geometry: { type: 'Point', coordinates: [form.realLocation?.longitude, form.realLocation?.latitude] } } as any
                                ]
                            }
                        }>
                            <Layer {...circleLayerStyle} />
                        </Source>
                        <ScaleControl unit='metric' />


                    </Map>
                    <div style={{ marginTop: 16, marginBottom: 16, display: 'flex', flexDirection: 'column', alignSelf: 'stretch', margin: '16px', textAlign: 'center' }}>
                        <ContainerBtm>
                            <ItalicHeading>Location accuracy</ItalicHeading>
                        </ContainerBtm>
                        <Select defaultValue={locAForMap} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleAccuracyChange(event.target.value)}>
                            <option value={100}>{'100 m'}</option>
                            <option value={5000}>{'5 km'}</option>
                            <option value={10000}>{'10 km'}</option>

                        </Select>
                    </div>
                    <div style={{ marginBottom: 40, marginTop: 15 }}>

                        <Button onPress={() => setSetLocationAccuracy(false)} title="OK!" />
                    </div>
                </InnerContainerNoTop>
            </ReportBase>
        )
    }


    return (
        <ReportBase>
            <InnerContainer>
                <LogoContainer>
                    <img src='./images/wellDoneText2x.png' width={244} height={37} />
                </LogoContainer>

                <ItalicHeading>Rating</ItalicHeading>
                <StarRating onRatingChange={setRating} />
                <TagSection>
                    {tags.lists.map((list, index) => {
                        const tagsForList: any = [...tags.curated.filter(tag => tag.list === list)]
                        const tagsSorted = tagsForList.sort((b, a) => (a.selected - b.selected))

                        return (
                            <div key={`list${list}`}>
                                <TaglistTitle>

                                    <ItalicHeading>{list}</ItalicHeading>
                                </TaglistTitle>
                                <TagBox>
                                    {tagsSorted.map((tag, index) => {
                                        if (tag.list === list) {

                                            return (
                                                <TagButton
                                                    key={tag.name}
                                                    active={tag.selected === true}
                                                    onClick={() => dispatch(selectTag(tag.name, listSourceName))}>
                                                    <BasicText>{tag.name}</BasicText>
                                                </TagButton>
                                            );
                                        } else {
                                            return null
                                        }
                                    })}
                                </TagBox>
                            </div>
                        );
                    })}
                </TagSection>
                <div style={{ marginTop: 16, marginBottom: 16, display: 'flex', flexDirection: 'column', alignSelf: 'stretch', margin: '16px', textAlign: 'center' }}>
                    <ContainerBtm>
                        <ItalicHeading>Location accuracy</ItalicHeading>
                    </ContainerBtm>
                    <div onClick={() => setSetLocationAccuracy(true)} style={{ borderColor: Color.Pink, borderStyle: 'solid', borderWidth: '4px', padding: '8px 32px', alignSelf: 'center' }}>
                        <ItalicHeadingBigPink>{locationNowLabel}</ItalicHeadingBigPink>
                    </div>
                    {/*          <Select defaultValue={100} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleAccuracyChange(event.target.value)}>
                        <option value={100}>{'100 m'}</option>
                        <option value={5000}>{'5 km'}</option>
                        <option value={10000}>{'10 km'}</option>

                    </Select> */}
                </div>
                <div style={{ marginBottom: 40, marginTop: 15 }}>

                    <Button onPress={() => validateAndPostReport()} title="Moan!" />
                </div>
            </InnerContainer>

        </ReportBase>
    )
}