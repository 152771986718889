import { UPDATE_SESSION, SET_TAG_ACTIVE, SET_CURATED_TAGS, RESET_TAG_SELECTIONS, ADD_COMMUNITY_TAG, TagsState, TagsActionTypes } from "./types";
import Tag from "../../interfaces/Tag";
import _ from "lodash";

const initialState: TagsState = {
  lists: [],
  curated: [],
  community: [],
  curatedForFilters: [],
};

export function tagsReducer(
  state = initialState,
  action: any
): TagsState {
  switch (action.type) {
    case SET_CURATED_TAGS: {
      return {
        ...state,
        [action.payload.type]: action.payload.tags,
        curatedForFilters: _.cloneDeep(action.payload.tags),
        lists: action.payload.lists
      };
    }
    case RESET_TAG_SELECTIONS:
      const curatedArr = [...state.curated].map(tag => {
        tag.selected = false
        return tag
      })
      const communityArr = [...state.community].map(tag => {
        tag.selected = false
        return tag
      })
      return {
        ...state,
        curated: curatedArr,
        community: communityArr
      }

    case SET_TAG_ACTIVE:
      let type = action.payload.type
      let name = action.payload.name
      let newArr
      if (type === 'curated') {
        newArr = [...state.curated]
      } else if (type === 'community') {
        newArr = [...state.community]
      } else if (type === 'curatedForFilters') {
        newArr = [...state.curatedForFilters]
      }
      let index = _.findIndex(newArr, { 'name': name });

      let newValue = newArr[index].selected
      newArr[index].selected = !newValue


      return {
        ...state,
        [type]: newArr,
      }

    default:
      return state;
  }
}
